@import "src/components/GlobarVars";

.emailyHeaders{
  padding: 10px;
  margin-top: 15px;
  .content{
    display: flex;
    flex-direction: column;
    max-width: 800px;
    margin-top: 15px;
    .item{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      background-color: #fff;
      padding: 5px;
      border-radius: 3px;
      margin: 5px;
      .i {
        cursor: pointer;
      }
      .more{
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 5px;
        .item3{
          margin: 3px;
          display: flex;
          flex-direction: row;
          .item4{
            padding: 2px 6px;
            border: 1px solid $blueMenu;
            border-radius: 3px;
            display: inline-block;
            margin: 2px;
          }
        }
        .column{
          flex-direction: column;
        }
      }
      .item2{
        margin: 5px;
        .i{
          color: $blueMenu;
          margin-right: 5px;
          font-size: 18pt;
          :hover{
            color: $blue_dark;
          }
        }
      }
      .ctrl{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: auto;
      }
    }
  }
  .textEmailu{
    background-color: $gray_verylight;
    padding: 25px 20px;
    border-radius: 5px;
    font-size: 14px;
    h5{
      margin: 0 0 5px 0;
      font-size: 16px;
    }
  }
}