@import "./src/components/GlobarVars";
.modalNovinka{
  max-width: 800px;
  margin: auto;
  color: black;
  text-align: center;
  .uvod{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    .img{
      width: 100%;
      margin-top: 5px;
      img{
        max-width: 100%;
      }
    }
    .uvodText{
      width: 100%;
      font-style: italic;
      color: $blueMenu;
    }
  }
}
.novinkyClenDashboard{
  .container{
    display: flex;
    flex-direction: column;
    
    .item{
      padding: 10px 12px;
      border-radius: 5px;
      margin: 3px;
      background-color: #fff;
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      color: $blueMenu;
      font-size: 16pt;
      .cont1{
        width: 120px;
        margin-right: 10px;
      }
      .cont2{
        display: flex;
        flex-direction: column;
        
      }
      
     
      .headImg{
        width: 120px;
        border-radius: 7px;
      }
      .noPhoto{
        color: gray;
        font-size: 27pt;
        margin: 6px 0 0 10px;
        opacity: 0.7;
      }
      .date{
        font-size: 13pt;
        margin-right: 10px;
        .i{
          font-size: 14pt;
          margin-right: 10px;
          cursor: pointer;
          &:hover{
            color: $blue_dark;
          }
        }
      }
      .titulek{
        font-size: 14pt;
        color: black;
      }
    }
  }
}