@import '../ClientApp/src/components/GlobarVars';

.dash_novyClenove{
  background-color: #fff;
  padding: 6px 15px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  .text{
    color: $blueMenu;
  }
  .no{
    margin-left: 10px;
    background-color: $blueMenu;
    padding: 3px 8px;
    border-radius: 3px;
    color: #fff;
  }
}