.finance {
  display: flex;
  flex-direction: column;
  .filter {

    background-color: white;
    padding: 30px;

    .var-symbol{
      margin-left: 15px;
    }

    .filter-item {
      margin-top: 15px;

      input{
        margin-right: 10px;
      }
    }
  }
}


/* ----
MEDIA QUERIES
-------*/
@media only screen and (max-width: 600px) {
  .finance{
    .filter{
      padding: 3px; 
      width: 100%;
      .filter-item{
        label{
          display: flex;
          font-size: 12pt;
          input[type="radio"]{
            width: 20px;
          }
        }
        .var-symbol{
          margin-left: 0;
            width: 250px;
        }
        
        
      }
    }
  }
}