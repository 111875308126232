@import "src/components/GlobarVars";

.dashboard{
  
  .info{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .item{
      padding: 5px;
      border-radius: 5px;
      //background-color: #fff;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      margin: 5px;
      max-width: 350px;
      a{
        color: $blueMenu;
        text-decoration: underline;
        cursor: pointer;
      }
      .item2{
        padding: 2px 8px;
        margin: 2px;
      }
      .value{
        background-color: $blueMenu;
        border-radius: 2px;
        color: #fff;
        width: max-content;
      }
      .link{
          color: $blueMenu;
          border-radius: 2px;
          width: max-content;
      }
    }
  }
}

.dashBlocksContainer{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 10px 0;
  .dashBlock{
    display: flex;
    flex-direction: column;
    h3{
      margin-top: 0;
      margin-bottom: 5px !important;
    }
    .content{
      padding: 15px 20px;
      border-radius: 5px;
      //border: 1px solid $blueMenu;
      box-shadow: $boxShadow-light;
      background-color: $blue_light;

    }
  }
  .dashBlock-M{
    max-width: 800px;
    min-width: 650px;
    margin: 10px 5px;
    border: 1px solid #d0d0d0;
    padding: 10px 20px;
    border-radius: 5px;
    background-color: #e3e3e3;
    h1{
      color: $blueMenu;
      font-size: 22pt;
      .i{
        margin-right: 10px;
      }
    }

  }


}

@media only screen and (max-width: 750px) {
  .dashBlocksContainer{

    .dashBlock-M{
      min-width: 90%;
      max-width: none;
      h1{
        color: $blueMenu;
        font-size: 22pt;
      }

    }


  }
}
