@import '../GlobarVars';

menu{
    position: fixed;
    margin: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    z-index: 9999;
    background-color: $blueMenu;
    display: flex;
    flex-direction: column;
    padding: 20px 20px 40px 20px;
    color: #fff;
    overflow: auto;

    .header{
        width: 100%;
        padding: 5px;
        color: $blue_dark;
        font-size: 22pt;
        box-sizing: border-box;
    }

    .content{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .group{
            width: 290px;
            margin: 10px;

            .header2{
                color: $blue_dark;
            }
            .content2{

                a{
                    text-decoration: none;
                    color: #fff;
                    display: block;
                }
                .item{
                    margin: 10px 0;
                    padding: 6px 15px;
                    border: 1px solid #fff;
                    border-radius: 20px;
                    opacity: 0.8;
                    cursor: pointer;
                    &:hover{
                        opacity: 1;
                    }
                }
            }

        }

    }
    
    .close{
        position: fixed;
        top: 20px;
        right: 20px;
        padding: 10px 14px;
        border-radius: 100%;
        border: 1px solid #fff;
        display: flex;
        justify-content: flex-end;
        z-index: 99;
        color: #fff;
        background-color: inherit;
        cursor: pointer;
        opacity: 0.7;
        &:hover{
            opacity: 1;
        }
       
    }

}

#showMenuButt{
    position: fixed;
    margin: 0;
    bottom: 6px;
    right: 25px;
    padding: 7px 17px;
    z-index: 99;
    background-color: #5037FF;
    border-radius: 100%;
    color: #fff;
    cursor: pointer;
    opacity: 0.9;
    &:hover{
        opacity: 1;
    }

}