@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,700;1,400&family=Roboto+Slab:wght@300;400;700&display=swap');

$gray_verylight: #ececec;
$gray: #cdcdcd;
$blueMenu: #3693D0;
$blue_dark: #1c1273;
$blue_light: #e5e4fd;
$blue_infoBox: #ecf6fd;
$green_save: #2fb646;
$red_mild: #b63434;

$body: 'Open Sans', sans-serif;
$header: 'Roboto Slab', serif;

$boxShadow-light: 0 0 5px -1px rgba(0,0,0,0.5);

