@import '../GlobarVars.scss';

.treninkyVypis{
    display: flex;
    flex-direction: column;
    
    .activeBtn{
        background-color: $blueMenu;
        color: #FFFFFF;
    }
    
    .note{
        font-size: 14px;
    }
    
    .den{
        margin-right: 10px;
        color: $blueMenu;
        font-weight: bold;
        font-size: 16pt;
    }
    .datum{
        color: $blueMenu;
        font-size: 16pt;
    }
    .item{
        h2{
            margin: 10px 0 5px 0;
        }
        .item2{
            max-width: 650px;
            display: flex;
            flex-direction: column;
            .item3{
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                padding: 10px;
                border-radius: 5px;
                margin: 2px;
                background-color: #fff;
                align-items: flex-start;

                .i{
                    cursor: pointer;
                    margin-right: 5px;
                    color: $blueMenu;
                    &:hover{
                        color: $blue_dark;
                    }
                }

                &:hover{
                    background-color: $blue_light;
                }
                
                .item3_2{
                    display: flex;
                    width: 100%;
                    flex-wrap: wrap;
                   
                    .item4{
                        margin: 2px 4px 2px 2px;
                        display: block;
                    }
                    
                    .nazev{
                        font-weight: bold;
                        font-size: 13pt;
                    }
                    .ctrl{
                        margin-left: auto;
                        font-weight: normal;
                        width: 100%;
                        color: $blueMenu;
                        .form-group{
                            margin-top: 3px;
                            width: 100%;

                        }
                        #note{
                            width: 100%;
                        }
                        
                        .stavBtns{
                            margin-top: 10px;
                            button{
                                padding: 5px 10px;
                            }
                        }
                    }
                }
                

            }
            .misto{
                display: flex;
                flex-direction: column !important;
                padding: 10px;
                border-radius: 5px;
                margin: 2px;
                background-color: #fff;

                .itemMisto{
                    margin: 5px;
                    textarea{
                        height: 50px;
                        width: 100%;
                        min-height: 30px;
                    }
                }
                .map{
                    color: $blueMenu;
                    a{
                        color: $blueMenu;
                    }
                }
            }
            .pocetClenu{
                color: $blueMenu;
            }
            .neaktivniIcon{
                .i{
                    color: $red_mild;
                    cursor: default !important;
                }

            }
            .zadnyClen{
                padding: 5px;
                color: $red_mild;
                background-color: white;
                border: 1px solid $red_mild;
                border-radius: 5px;
                font-size: 12pt;
                opacity: 0.6;
            }
         
        }
        
    }
}

    