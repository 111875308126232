@import '../ClientApp/src/components/GlobarVars';

.adminMenu{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  a{
    padding: 1px 6px;
    border: 1px solid $blueMenu;
    background-color: #fff;
    font-size: 11pt;
    border-radius: 3px;
    margin: 2px;
    text-decoration: none;
    color: $blueMenu;
    .i{
      margin-right: 2px;
    }
    &:hover{
      background-color: $blueMenu;
      color: #fff;
    }
  }
}