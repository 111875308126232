@import '../GlobarVars.scss';

.platbyCont{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 20px;
  .item{
    padding: 10px 10px;
    border-radius: 5px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 5px 5px 5px 0;
    .item2{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
     
      .item3{
        margin: 3px;
        padding: 3px 10px;
        background-color:  #f7f7f7;
        border-radius: 3px;
        .key{
          color: $blueMenu;
          min-width: 110px;
          display: inline-block;
          
          
        }
        .value{
          color: black;
          margin-left: 5px;
          min-width: 116px;
          display: inline-block;
        }
      }
    }
  }
  .pdfDown{
    color: $green_save;
  }
}