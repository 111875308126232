@import '../GlobarVars';



.modal-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
  .close {
    position: fixed;
    top: 2%;
    right: 3%;
    border: 0;
    z-index: 9999;
    background-color: #f9f9f9;
    padding: 3px 8px;
    border-radius: 100%;
    box-shadow: 0 0 7px -3px rgba(0, 0, 0, 0.5);


    button{
      font-size: 16pt;
      color: $blueMenu;
      background-color: inherit;
      margin: 0;
      &:hover{
        color: $red_mild;
      }
    }
  }


}



.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-box {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 84%;
  width: 90%;
  overflow: auto;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  z-index: 101;
  padding: 40px;
  border-radius: 8px;

}

/* ----
MEDIA QUERIES
-------*/
@media only screen and (max-width: 600px) {
  
  .modal-box{
    padding: 10px 0;
    width: 98%;
    height: 89%;
    top: 47%;
  }
  .modal-wrapper {

    .close {
      top: 2%;
    }
  }
}