@import "src/components/GlobarVars";

.dochazkaTrenink{
  .dochazkaClen{
      display: flex;
    .stav{
      width: 60px;
      background-color: $blueMenu;
      border: 1px solid $blueMenu;
      border-radius: 4px;
      text-align: center;
      color: #FFFFFF;
      font-size: 14px;
      margin-right: 10px;
      height: 20px;
    }
    .poznamka{
      font-size: 11pt;
      font-style: italic;
      color: gray;
    }
    
  }

  .nezaplaceno{
    color: darkred;
    margin-left: 10px;
  }

  .info{
    .container{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .item{
        padding: 4px 6px;
        border: 1px solid $blueMenu;
        border-radius: 3px;
        background-color: $gray_verylight;
        margin: 5px;


        label{
          display: inline-block;
          margin-right: 5px;
          color: $blueMenu;
        }
        .value{
          color: black;
        }
      }

    }
  }

  .dochazka{
    .clenoveContainer{
      display: flex;
      flex-direction: column;
      .item{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 10px;
        border-radius: 5px;
        margin: 4px;
        background-color: $gray_verylight;
        align-items: center;
        .jmeno{
          margin-right: 10px;
          width: 220px;
          color: $blueMenu;
        }
        //border: 1px solid $blueMenu;
        &:hover{
          background-color: $blue_light;
        }
        .checkbox{
          width: 40px;
        }
      }
    }
    .container{
      margin-top: 15px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .poznamka{
        display: flex;
        flex-direction: column;
      }
    }
    .ctrl{
      margin-top: 15px;
    }
  }
}



/* ----
MEDIA QUERIES
-------*/
@media only screen and (max-width: 600px) {
  .dochazkaTrenink{
    h1{
      font-size: 18pt;
    }
    .info{
      .container{
        padding: 6px 0;
        
      }
    }
    .dochazka{
      .clenoveContainer{
        .item{
          padding: 10px 6px;
          .item2{
            width: 100%;
            margin-bottom: 5px;
            input{
              width: auto;
            }

          }
        }
      }
      
    }
  }
  
}
