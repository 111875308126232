@import './../GlobarVars.scss';

.carousel-table {
  .actions {
    margin-left: 30px;
    display: flex;
    flex-direction: column;
  }

  .item {
    padding: 20px;
    border-bottom: 1px solid gray;
    
    .chck{
      display: flex;
      flex-direction: row;
    }

    .item-div {
      margin-left: 25px;
      display: flex;
      align-items: center;
    }

    .showPublic {
      margin-right: 10px;
    }

    img {
      max-width: 150px;
    }
  }
}