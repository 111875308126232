@import '../GlobarVars.scss';

.akceCont{
  display: flex;
  flex-direction: column;

  .akceItem{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 6px 12px;
    border-radius: 5px;
    margin: 3px;
    background-color: #fff;
    color: $blueMenu;
    font-size: 16pt;
    .nazev{
      color: black;
    }

    .item2{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-right: 5px;
      align-items: center;
      width: 100%;


    }
    .date{
      font-size: 12pt;
      margin-right: 10px;
    }
    .ctrl{
      margin-top: 5px;
      font-weight: normal;
      color: $blueMenu;
      align-items: center;
      display: flex;
      width: 100%;
      .dochazka{
        width: 100%;
      }
      #note{
        width: 100%;
      }
      .form-group{
        width: 100%;
      }
      .stavBtns{
        margin-top: 5px;
        button{
          padding: 5px 10px;
        }
      }

      .i{
        font-size: 18pt;
        margin-right: 10px;
        cursor: pointer;
        &:hover{
          color: $blue_dark;
        }
      }
      .checkbox{
        margin-right: 20px;
      }
    }
    .expandContainer{
      width: 100%;
      .uvod{
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        .img{
          width: 100%;
          margin-top: 5px;
          img{
            max-width: 100%;
          }
        }
        .uvodText{
          width: 100%;
          font-style: italic;
          color: $blueMenu;
        }
      }
      .itemAkce{
        width: 100%;
      }
      .dpopis{
        color: black;
      }
    }


  }
}