  @import './../GlobarVars.scss';

.carousel-images {
  .image-preview {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 5px;
    padding: 5px;
    .image-item{
      display: flex;
      flex-direction: row;
      .image-item__btn-wrapper{
        .binBtn{
          background-color: $red_mild;
          padding: 10px;
          color: white;
          border-radius: 5px;
          position: absolute;
        }

      }
      img{
        width: 250px;
        margin: 10px;
      }
    }
    
  }

  .upload-button {
    background-color: $blueMenu;
    color: white;
    padding: 15px;
    border-radius: 5px;
  }
}