@import '../GlobarVars.scss';


.modalAkce{
  max-width: 900px;
  margin: auto;
  color: black;
  text-align: center;
  .uvod{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    .img{
      width: 100%;
      margin-top: 5px;
      img{
        max-width: 100%;
      }
    }
    .uvodText{
      width: 100%;
      font-style: italic;
      color: $blueMenu;
    }
  }
}

.akceItem{
  .dochazka {
    display: flex;
    flex-direction: column;
  }
  .zadnyClen{
    padding: 5px;
    color: $red_mild;
    background-color: white;
    border: 1px solid $red_mild;
    border-radius: 5px;
    font-size: 12pt;
    opacity: 0.6;
  }
  
  label{
    font-size: 14px;
  }

  .activeBtn{
    background-color: $blueMenu;
    color: #FFFFFF;
  }
}