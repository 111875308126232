@import '../../GlobarVars.scss';

.treninkyVypisDashboard{
  display: flex;
  flex-direction: column;
  .den{
    margin-right: 10px;
    color: $blueMenu;
    font-weight: bold;
    font-size: 16pt;
  }
  .datum{
    color: $blueMenu;
    font-size: 16pt;
  }
  .item{
    h2{
      margin: 5px 0 5px 0;
      font-size: 14pt;
    }
    .item2{
      display: flex;
      flex-direction: column;
      .item3{
        padding: 6px 12px;
        border-radius: 5px;
        margin: 3px;
        background-color: #fff;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        color: $blueMenu;
        font-size: 16pt;

        .item4{
          margin: 2px 4px 2px 2px;
          display: block;
        }
        .nazev{
          color: black;
        }
        .time{
          font-size: 14pt;
        }
        .ctrl{
          display: flex;
          align-items: center;
          margin-left: auto;
          font-weight: normal;
          color: $blueMenu;
          .i{
            cursor: pointer;
            font-size: 18pt;
            margin-right: 10px;
            &:hover{
              color: $blue_dark;
            }
          }
          .stavBtns{
            margin-top: 5px;
          }
        }
        .pocetClenu{
          margin-left: 15px;
          padding: 2px 8px;
          display: inline-block;
          font-size: 14pt;
          background-color: $blue_light;
          color: $blue_dark;
          border-radius: 3px;
          cursor: pointer;
          &:hover{
            background-color: $blueMenu;
          }
        }

      }
      .misto{
        display: flex;
        flex-direction: column !important;
        padding: 10px;
        border-radius: 5px;
        margin: 2px;
        background-color: #fff;

        .itemMisto{
          margin: 5px;
        }
      }

    }

  }
}