.clen-fotky-upload{
  position: relative;

  .description{
    max-width: 240px;
    font-size: 14px;
  }

  .item{
    &.fotka-box {
      align-items: flex-start;
    }
  }
  .imagePreview{
    position: relative;
    img{
      max-width: 250px;
    }

    .remove{
      position: absolute;
      top: 5px;
      right: 25px;
      color: white;
      background-color: #b63434;
      padding: 5px;
      border-right: 5px;
    }
  }
}