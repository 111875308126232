@import "src/components/GlobarVars";

.emaily{
  
  .upload {
    background-color: lightgray;
    padding: 10px;
  }
  
  .subMenu{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 15px;
    button{
      margin: 3px;
    }
  }
  .selectEmail{
    max-width: 800px;
    box-sizing: border-box;
    margin-bottom: 25px;
  }
  .email{
    padding: 10px;
    border-radius: 3px;
    background-color: #fff;
    display: flex;
    flex-direction: column;

    max-width: 800px;
    .item{
      display: flex;
      flex-direction: column;
      margin: 5px 0;
      input[type='text']{
        width: 100%;
        box-sizing: border-box;
        font-size: 12pt;
        font-weight: bold;
        font-family: $body;
      }
      textarea{
        width: 100%;
        box-sizing: border-box;
        min-height: 200px;
        font-size: 12pt;
        padding: 5px;
      }
    }
  }
}