@import '../GlobarVars';

.backToDashboard{
  display: inline-block;
  a{
    color: $blueMenu;
    &:hover{
      color: $blue_dark;
    }
  }

}