@import './GlobarVars.scss';

.containerGlobal{
    padding: 20px;
}

a{
    color: black;
    text-decoration: none;

    &:hover{
        text-decoration: underline;
    }

    &:visited{
        color: black;
    }
}

.i{
    margin-right: 10px;
}

body{
    padding: 0;
    margin: 0;
    font-family: $body;
    background-color: $gray_verylight;
    font-size: 14pt;
}

input{
    box-sizing: border-box;
    padding: 5px 5px;
    border-radius: 3px;
    outline: none;
    border: 1px solid $gray;
    &:focus{
        border: 1px solid $blueMenu;
        background-color: $blue_light;
    }
    &:read-only{
        background-color: $gray_verylight;
        border: 1px solid $gray;
    }
}



textarea{
    border-radius: 3px;
    padding: 5px 5px;
    box-sizing: border-box;
    outline: none;
    border: 1px solid $gray;
        min-width: 280px;
        min-height: 80px;
    &:focus{
        border: 1px solid $blueMenu;
        background-color: $blue_light;
    }

}

select{
    padding: 5px 5px;
    border-radius: 3px;
    outline: none;
    border: 1px solid $gray;
    min-width: 200px;
    box-sizing: border-box;
    background-color: #fff;
    &:focus{
        border: 1px solid $blueMenu;
        background-color: $blue_light;
    }
    
}

input[type="checkbox"]{
    transform: scale(1.8);
    cursor: pointer;
}

div{
    display: block;
}

div, button, input, select{
    outline: none;
}

button{
    border: none;
    cursor: pointer;
}

h1,h2,h3{
    font-family: $header;
    font-weight: lighter;
}

h1{
    margin: 0 0 15px 0;
    color: $blueMenu;
}

h2{
    margin: 15px 0 10px 0;
}

h4{
    font-size: 16pt;
    color: black;
    font-weight: normal;
}

.subMenu{
    display: flex;
    flex-direction: row;
    padding: 10px;
    align-items: center;
    flex-wrap: wrap;
    a{
        text-decoration: none;
        font-family: $header;
        font-weight: lighter;
        padding: 0;
        margin: 0;
        box-sizing: border-box;
    }
    .item{
        margin: 5px;
        background-color: $blueMenu;
        padding: 6px 10px;
        border-radius: 4px;
        color: #fff;
        cursor: pointer;
        box-sizing: border-box;
        font-size: 14pt;
        &:hover{
            background-color: $blue_dark;
        }
    }
    .i{
        margin-right: 10px;
    }
}

.filtr{
    display: flex;
    flex-direction: row;
    padding: 11px;
    flex-wrap: wrap;
    background-color: #fff;
    border-radius: 5px;
    .item{
        padding: 5px;
    }
    .filtrSearch{
        display: flex;
        flex-direction: column;
        
        
    }
    
    .searchInputs{
        display: flex;
        flex-direction: column;
        .itemSearchInputs{
            margin: 5px;
            display: flex;
            flex-direction: row;
            label{
                width: 200px;
            }
            input{
                width: 200px;
            }
        }
    }
}

button{
    font-family: $header;
    font-weight: lighter;
    margin-right: 10px;
}

//big buttons
.btn-filtr{
    background-color: $blueMenu;
    padding: 10px 20px;
    border-radius: 4px;
    color: #fff;
    margin-top: 10px;
    font-size: 14pt;
    &:hover{
        background-color: $blue_dark;
    }
    .i{
        margin-right: 10px;
    }
}

.btn-save{
    padding: 10px 20px;
    border-radius: 4px;
    color: #fff;
    margin-top: 10px;
    font-size: 14pt;
    background-color: $green_save;
    &:hover{
        background-color: $blue_dark;
    }
    .i{
        margin-right: 10px;
    }
}
.btn-delete{
    padding: 10px 20px;
    border-radius: 4px;
    color: #fff;
    margin-top: 10px;
    font-size: 14pt;
    background-color: $red_mild;
    &:hover{
        background-color: $blue_dark;
    }
    .i{
        margin-right: 10px;
    }
}
.btn-otherActionOrange{
    padding: 10px 20px;
    border-radius: 4px;
    color: #fff;
    margin-top: 10px;
    font-size: 14pt;
    background-color: orange;
    &:hover{
        background-color: $blue_dark;
    }
    .i{
        margin-right: 10px;
    }
}

//other buttons
.btn-table-edit{
    background-color: $blueMenu;
    padding: 3px 7px;
    border-radius: 4px;
    color: #fff;
    font-size: 11pt;
    &:hover{
        background-color: $blue_dark;
    }
    .i{
        margin-right: 10px;
    }

    &.green{
        background-color: #2fb646;
    }
}

.btn-table-remove{
    background-color: #d80000;
    padding: 3px 7px;
    border-radius: 4px;
    color: #fff;
    font-size: 11pt;
    &:hover{
        background-color: darkred;
    }
    .i{
        margin-right: 10px;
    }

    &.green{
        background-color: #2fb646;
    }
}

.btn-1{
    background-color: $blueMenu;
    padding: 8px 16px;
    border-radius: 4px;
    color: #fff;
    font-size: 12pt;
    margin: 5px;
    &:hover{
        background-color: $blue_dark;
    }
    .i{
        margin-right: 10px;
    }
    
    &:disabled{
        cursor: not-allowed;
        background-color: $gray;
    }
}

.btn-foto-remove{
    font-size: 14pt;
    margin: 0 5px;
    color: $red_mild;
    &:hover{
        color: black;
    }
}

.form{
    background-color: #f9f9f9;
    padding: 35px 20px;
    border-radius: 5px;
    border: 1px solid #d9d9d9;
    margin: 5px 0;
    h1,h2{
        margin-top: 5px;
    }
    .body{
        display: flex;
        flex-direction: column;
        .item{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin: 8px;
            align-items: baseline;
        }
        label{
            width: 250px;
            font-size: 12pt;
        }
        label.small{
            font-size: 10pt;
        }
        input[type='text']{
            width: 270px;
        }
        select{
            width: 270px;
        }
        input[type='search']{
            width: 270px;
        }
        input[type='number']{
            width: 270px;
        }
        input[type='password']{
            width: 270px;
        }
        input[type='date']{
            width: 270px;
            color: black;
        }
        textarea{
            width: 360px;
            min-height: 200px;
        }
        label.value{
            padding: 5px 10px;
            border-radius: 3px;
            background-color: $blueMenu;
            color: #fff;
            width: auto;
        }
    }
    .footer{
        margin-top: 10px;
        .ctrl{
            display: flex;
            flex-direction: row;
            border-top: 1px solid #cacaca;
            padding-top: 5px;
            margin-top: 40px;
            
            .item{
                margin: 5px;
            }
        }
    }
}

.infoBox{
    background-color: $blue_infoBox;
    padding: 20px 20px;
    border-radius: 5px;
    border: 1px solid #d9d9d9;
    margin: 5px 0;
    h3{
        margin: 5px 0px;
        color: $blue_dark;
    }
}

.breadCrumb{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 10px;
    color: $blueMenu;
    font-size: 11pt;
    .item{
        margin: 3px;
    }
    .i{
        font-size: 10pt;
    }
}

.table{
    margin-top: 15px;
}

.showMenuButtFooter{
    width: 100%;
    height: 50px;
}

/**
  Start loader
 */
.lds-spinner {
    color: black;
    display: inline-block;
    width: 80px;
    height: 80px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999999;
}

.lds-spinner-hide {
    display: none;
}


.lds-spinner div {
    transform-origin: 40px 40px;
    animation: lds-spinner 1.2s linear infinite;
}

.lds-spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 37px;
    width: 6px;
    height: 18px;
    border-radius: 20%;
    background: black;
}

.lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
}

.lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
}

.lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
}

.lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
}

.lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
}

.lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
}

.lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
}

.lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
}

.lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
}

.lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
}

.lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
}

.lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
}

@keyframes lds-spinner {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

/**
 Konec loader
 */

.react-alerts .alert .message {
  -webkit-box-flex: 3;
  -webkit-flex: 3;
  -ms-flex: 3;
  flex: 3;
  text-transform: initial;
}


/* ----
MEDIA QUERIES
-------*/
@media only screen and (max-width: 600px) {
    
    h1{
        margin: 0 0 5px 0; 
    }
    
    h2{
        font-size: 17pt;
    }
    h3{
        font-size: 16pt;
    }
    
    

    .treninkyVypis{
        .item{
            .item2{
                .item3{
                    padding: 5px;
                   
                    .ctrl{
                        margin: 0;
                        width: 100% !important;
                        .form-group{
                            margin-bottom: 10px;
                        }
                        button{
                            padding: 10px 15px;
                        }
                        
                    
                        
                    }
                }
            }
        }
    }
    
    .containerGlobal{
        padding: 12px 5px;
        .dashboard{
            .info{
                .item{
                    padding: 0;
                }
            }
            .dashBlocksContainer{
                .dashBlock-M{
                    padding: 10px 5px;
                    margin: 10px 0;
                    width: 100%;
                    h1{
                        font-size: 17pt;
                    }
                    .dash_novyClenove{
                            padding: 6px;
                            .text{
                                font-size: 12pt;
                                width: 225px;
                            }
                            


                    }
                    .dash_clenovePlatReg{
                        .header{
                            padding: 0;
                            .text{
                                font-size: 12pt;
                                width: 225px;
                                padding: 6px;
                            }
                        }
                        .container{
                            padding: 6px 0;
                            .item{
                                .title{
                                    width: 180px;
                                }
                            }
                        }
                        
                    }
                    .novinkyClenDashboard{
                        .container{
                            padding: 6px 0;
                            .item{
                                padding: 8px;
                                .cont1{
                                    width: 80px;
                                    margin-right: 10px;
                                }
                                .headImg{
                                    width: 80px;
                                }
                                .noPhoto{
                                    font-size: 20pt;
                                    margin-left: 3px;
                                    margin-bottom: 5px;
                                    margin-top: 0;
                                }
                                .date{
                                    width: 100%;
                                }
                                .titulek{
                                    width: 100%;
                                    font-size: 12pt;
                                }
                                .ctrl{
                                    width: 100%;
                                    padding-top: 5px;
                                }
                                .expandContainer{
                                    .dpopis{
                                            img{
                                                max-width: 100%;
                                            }
                                    }
                                }
                            }
                        }
                    }
                    
                }
             
                
            }
           
        }
    }

    
    
    textarea{

        min-width: 220px;
        min-height: 80px;
        width: 100%;


    }
    input{
        width: 100%;
    }
    select{
        width: 100%;
    }
    .container{
        padding: 6px 6px;
    }
    .form{
        padding: 5px;
        .body {
            input[type="text"]{
                width: 100%;
            }
            input[type="date"]{
                width: 100%;
            }
            input[type="number"]{
                width: 100%;
            }
            input[type="email"]{
                width: 100%;
            }
            input[type="password"]{
                width: 100%;
            }
            select{
                width: 100%;
            }
            textarea{
                width: 100%;
            }
        }
    }
  
    .infoBox{
        padding: 10px 10px;
    }

 
    
    .filtr{
        padding: 5px;
        .item{
            width: 100%;
        }
    }
    
  


}