@import '../ClientApp/src/components/GlobarVars';

.dash_clenovePlatReg{
  .header{
    margin-top: 10px;
    background-color: #fff;
    padding: 6px 15px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    .text{
      color: $blueMenu;
    }
    .no{
      margin-left: 10px;
      background-color: $blueMenu;
      padding: 3px 8px;
      border-radius: 3px;
      color: #fff;
    }
    .ctrl{
      cursor: pointer;
      margin-left: 10px;
      color: $blueMenu;
      font-size: 18pt;
    }
  }
  .container{
    margin-top: 2px;
    background-color: #fff;
    padding: 6px 15px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .item{
      padding: 2px 5px;
      margin: 2px;
      font-size: 12pt;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .item2{
        padding: 2px;
      }
      .title{
        width: 190px;
        display: inline-block;
      }
      .timeSpan{
        background-color: $blueMenu;
        border-radius: 2px;
        color: #fff;
        padding: 2px 5px;
      }
    }
  }


}